import { lazy, Suspense, useEffect } from "react";

/// Components
import Index from "./jsx";
import { connect, useDispatch } from "react-redux";
import { Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
// action
import { checkAutoLogin } from "./services/AuthService";
import { isAuthenticated, isAdmin, isActive, isProfileComplete } from "./store/selectors/AuthSelectors";
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import "bootstrap-icons/font/bootstrap-icons.css";

const ReferredSignUp = lazy(() => import("./jsx/pages/ReferredRegistration"));
const ForgotPassword = lazy(() => import("./jsx/pages/ForgotPassword"));
const ResetPassword = lazy(() => import("./jsx/pages/ResetPassword"));
const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/Login")), 500);
  });
});
const SignUpRegister = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/SignUpRegister")), 500);
  });
});
const SignUpVerifyNewRegister = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/SignUpVerifyNewRegister")), 500);
  });
});
const SignUpSetPassword = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/SignUpSetPassword")), 500);
  });
});
const SignUpSetPromo = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/SignUpSetPromo")), 500);
  });
});
const SignUpAccountType = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/SignUpAccountType")), 500);
  });
});
const SignUpCompleteProfile = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/SignUpCompleteProfile")), 500);
  });
});
function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

function App(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const referralCode = queryParams.get("ref");
  const signUp = queryParams.get("signUp");
  //In case of Google SignIn
  const userObj = JSON.parse(queryParams.get("userObj"));
  // console.log(userObj);
  const resetToken = queryParams.get("token");
  const emailVerifyToken = queryParams.get("emailVerifyToken");
  const partnerId = queryParams.get("partnerId");

  // const user = {
  //   id: "001",
  //   name: "nv",
  //   email: "dj@gmail.com",
  //   createdAt: Math.floor(new Date().getTime() / 1000), // Example of Unix timestamp
  // };

  useEffect(() => {
    checkAutoLogin(dispatch, navigate, referralCode, resetToken, signUp, emailVerifyToken, partnerId, userObj);
  }, []);

  let routeblog = (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<SignUpRegister />} />
      <Route path="/verification-new-register" element={<SignUpVerifyNewRegister />} />
      <Route path="/set-password" element={<SignUpSetPassword />} />
      <Route path="/register-done" element={<SignUpSetPromo />} /> {/* for Linking referral singups */}
      <Route path="/account-type" element={<SignUpAccountType />} />
      <Route path="/complete-profile" element={<SignUpCompleteProfile />} />
      <Route path="/referral" element={<ReferredSignUp />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/page-forgot-password" element={<ForgotPassword />} />
      <Route path="*" element={<Login />} />
    </Routes>
  );
  if (props.isAuthenticated) {
    if (props.isAdmin) {
      return (
        <>
          <Suspense
            fallback={
              <div id="preloader">
                <div className="sk-three-bounce">
                  <div className="sk-child sk-bounce1"></div>
                  <div className="sk-child sk-bounce2"></div>
                  <div className="sk-child sk-bounce3"></div>
                </div>
              </div>
            }
          >
            {/* <AdminIndex /> */}
          </Suspense>
        </>
      );
    } else if (props.isActive && props.isProfileComplete) {
      return (
        <>
          <Suspense
            fallback={
              <div id="preloader">
                <div className="sk-three-bounce">
                  <div className="sk-child sk-bounce1"></div>
                  <div className="sk-child sk-bounce2"></div>
                  <div className="sk-child sk-bounce3"></div>
                </div>
              </div>
            }
          >
            <Index />
          </Suspense>
        </>
      );
    } else {
      return (
        <>
          <Suspense
            fallback={
              <div id="preloader">
                <div className="sk-three-bounce">
                  <div className="sk-child sk-bounce1"></div>
                  <div className="sk-child sk-bounce2"></div>
                  <div className="sk-child sk-bounce3"></div>
                </div>
              </div>
            }
          >
            {routeblog}
          </Suspense>
        </>
      );
    }
  } else {
    return (
      <div className="vh-100">
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          {routeblog}
        </Suspense>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAdmin: isAdmin(state),
    isAuthenticated: isAuthenticated(state),
    isActive: isActive(state),
    isProfileComplete: isProfileComplete(state),
  };
};

//export default connect((mapStateToProps)(App));
export default withRouter(connect(mapStateToProps)(App));
